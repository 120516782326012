
const Theme = {
	colors:{
		neutral: 'grey',
		neutralLightest: 'white',
		secondary: '#005E5D',
		secondaryLightest: '#78FAFF',
		secondaryLighter: '#009286',
		secondaryDarker: '#004C4C',
		primary: '#FFD22E'
	},
	responsive:{
		media : {
			xs: '400px',
			sm: '600px',
			lg: '1025px',
			xl: '1440px',
		},
		whitespace : {
			xs: 25,
			sm: 25,
			lg: 50,
			xl: 100,
		}
	}
};

export default Theme;
