import { Raycaster } from 'three/src/Three';
import { useThree } from '@react-three/fiber';
import { useEffect, useState } from 'react';
import { Vector2 } from 'three';
import { useGlobalContext } from '../context/contexts/global/GlobalContext';

const cursorRaycast = new Raycaster();

export const CursorState = () => {

	const {gameState} = useGlobalContext();
	const {camera, scene} = useThree();

	const [cursorPosition, setCursorPosition] = useState<[number, number] | undefined>([0,0]);

	const cursorUpdate = (e) => {
		setCursorPosition([e.pageX, e.pageY]);
	};

	useEffect(() => {
		if(gameState !== 2) document.body.style.cursor = 'auto';
		if(!cursorPosition || gameState !== 2) return;
		const normalizedValues = {
			x: (cursorPosition[0] / window.innerWidth) * 2 - 1, 
			y: -(cursorPosition[1] / window.innerHeight) * 2 + 1, 
		};

		cursorRaycast.setFromCamera(new Vector2(normalizedValues.x, normalizedValues.y), camera);
		
		const intersects = cursorRaycast.intersectObjects(scene.children);

		let pointer = false;
		for(let i = 0; i < intersects.length; i++){
			
			if(!intersects[i]) continue;

			if(intersects[i].object.userData.skipForHoverState) continue;

			if(intersects[i].object.userData.interactable){
				pointer = true;
				break;
			}
		}

		document.body.style.cursor = pointer ? 'pointer' : 'auto';

	},[cursorPosition, gameState]);

	useEffect(() => {
		document.addEventListener('mousemove', cursorUpdate);
		return () => {
			document.removeEventListener('mousemove', cursorUpdate);
		};
	},[]);
	
	return(
		<></>
	);
};