import { useEffect, useState } from 'react';
import { useGlobalContext } from '../context/contexts/global/GlobalContext';

const basefocalOffset = 8;

export const ThreeCameraActions = (props: CameraActionsProps) => {
	
	const {controls, gameState} = useGlobalContext();

	const [focalOffset, setFocalOffset] = useState<number>(0);

	useEffect(() => {
		//
	},[focalOffset]);

	useEffect(() => {
		window.addEventListener('resize', resizeHandler);
		calculateFocalOffset();

		return () => {
			window.removeEventListener('resize', resizeHandler);
		};
	},[]);

	const resizeHandler = () => {
		calculateFocalOffset();
	};

	// calculates the focalOffset based on screen width.
	// note: might need to tweak this. I see that this calculation only covers a few resolutions well and the rest are a bit awkward.
	const calculateFocalOffset = () => {
		if(window.innerWidth <= 1920){
			setFocalOffset((window.innerWidth / 1920) * basefocalOffset);
		}
		else{
			setFocalOffset((window.innerWidth / 2560) * basefocalOffset);
		}
	};

	// keeps an eye on gameState to call upon cameraActions
	useEffect(() => {
		if(!controls || !props.object || gameState === undefined) return;
		cameraActions(gameState);
	},[gameState, controls, props.object]);

	const [firstAction, setFirstAction] = useState<boolean>(true);

	// sets camera based on game state
	const cameraActions = async(action: number) => {
		if(!controls || !props.object) return;
		const enableTransition = firstAction ? false : true;

		switch(action){
		default:
			controls.fitToBox(props.object, enableTransition, {paddingLeft:1, paddingRight: 1, paddingBottom: 3, paddingTop: 1}); 
			controls.rotateTo(-Math.PI / 4, Math.PI / 2.5, enableTransition);
			controls.enabled = true;
			break;
		case 3:
		case 1:
			if(firstAction)
				controls.fitToBox(props.object, enableTransition, {paddingLeft:1, paddingRight: 1, paddingBottom: 3, paddingTop: 1}); 

			controls.setFocalOffset(-focalOffset,0,0, enableTransition);
			controls.rotateTo(-Math.PI / 6, Math.PI / 2.5, enableTransition);
			controls.enabled = false;
			break;
		}

		if(firstAction)
			setFirstAction(false);

	};
	
	return (
		<>
		</>
	);
};

// types

type CameraActionsProps = {
	object: THREE.Object3D | null;
}