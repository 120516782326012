import { useEffect, useState } from 'react';
import { useGlobalContext } from '../../../context/contexts/global/GlobalContext';
import { content } from '../../Content/data';

export const DCContent = () => {

	const {setActiveContentID, activeContentID} = useGlobalContext();

	return(
		<>
			<label>content</label>
			<select onChange={(e) => setActiveContentID && setActiveContentID(parseInt(e.currentTarget.value))} value={activeContentID}>
				{ [...Array(content.length)].map((option, index) => <option key={`dc-general-gamestate-option-${index}`}>{index}</option>)}
			</select>
		</>
	);
};