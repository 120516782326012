/* eslint-disable */
import { extend, useFrame, useThree } from '@react-three/fiber';
import { useEffect, useRef } from 'react';
import { useGlobalContext } from '../context/contexts/global/GlobalContext';
import * as THREE from 'three';
import { CameraControls } from '../utilities/YomatsuCameraControls';

export const ThreeControls = () => {

	const { setControls } = useGlobalContext();

	const cameraControls = useRef<CameraControls>(null);

	useEffect(() => {
		if(!cameraControls.current)return;
		setControls && setControls(cameraControls.current)
	},[cameraControls.current])

	return (
		<CameraControls ref={cameraControls}/>
	);
};