import { ReactNode } from 'react';
import { GlobalContextProvider } from './contexts/global/GlobalContext';

const ContextProvider = (props:{children: ReactNode}) => {
	return (		
		<GlobalContextProvider>		
			{props.children}
		</GlobalContextProvider>
	);
};

export default ContextProvider;