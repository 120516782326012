import { CircleGeometry, CylinderGeometry, MeshBasicMaterial, PlaneGeometry, DoubleSide} from 'three';
import { WorkItem } from './WorkItem';

import storyIcon from '../../assets/images/workitemsIcons/storyIcon.png';
import epicIcon from '../../assets/images/workitemsIcons/epicIcon.png';
import episodeIcon from '../../assets/images/workitemsIcons/episodeIcon.png';
import themeIcon from '../../assets/images/workitemsIcons/themeIcon.png';
import sagaIcon from '../../assets/images/workitemsIcons/sagaIcon.png';

import { useLoader } from '@react-three/fiber';
import { TextureLoader } from 'three/src/loaders/TextureLoader';
import Theme from '../../style/theme';

export const WorkItems = () => {

	const [storyTexture, epicTexture, episodeTexture, themeTexture, sagaTexture] = useLoader(TextureLoader, [
		storyIcon,
		epicIcon,
		episodeIcon,
		themeIcon,
		sagaIcon,
	]);

	const geometry = {
		circle: new CircleGeometry(0.1,20,undefined,undefined),
		tube: new CylinderGeometry(0.01,0.01, 0.3, 3)
	};
	
	const materials = {
		transparant: new MeshBasicMaterial({color: 'white', transparent: true, opacity: 0.75}),
		hover: new MeshBasicMaterial({color: Theme.colors.primary}),
		storyMaterial: new MeshBasicMaterial({map: storyTexture, transparent: true, side: DoubleSide}),
		epicMaterial: new MeshBasicMaterial({map: epicTexture, transparent: true, side: DoubleSide}),
		episodeMaterial: new MeshBasicMaterial({map: episodeTexture, transparent: true, side: DoubleSide}),
		themeMaterial: new MeshBasicMaterial({map: themeTexture, transparent: true, side: DoubleSide}),
		sagaMaterial: new MeshBasicMaterial({map: sagaTexture, transparent: true, side: DoubleSide}),
	};

	return (
		<>
			{ items.map((group, groupIndex) => {
				return group.items.map((item, itemIndex) =>
					<WorkItem 
						key={`workitem-${groupIndex}-${itemIndex}`} 
						position={[item.positionX,group.layerHeight,item.positionZ]}
						passGeometry={geometry} 
						passMaterials={materials}
						contentId={group.contentId}
						groupData={group}
						poi={itemIndex === 0 ? true : false}
					/>
				);
			})}
		</>
	);
};

// types

export type WorkItemGeometry = {
	circle: CircleGeometry,
	tube: CylinderGeometry,
}
export type WorkItemMaterials = {
	transparant: MeshBasicMaterial,
	hover: MeshBasicMaterial,
	storyMaterial: MeshBasicMaterial,
}

export type WorkItemData = {
	material: string,
	layerHeight: number,
	contentId: number,
	items:
		{
			positionX: number,
			positionZ: number,
		}[]
}

// data

const items: WorkItemData[] = [
	{ // layer 1
		material: 'storyMaterial',
		layerHeight: 0.26,
		contentId: 5,
		items:[
			{
				positionX: -4,
				positionZ: 4
			},
			{
				positionX: 0,
				positionZ: 4
			},
			{
				positionX: 3,
				positionZ: 4.4
			},
			{
				positionX: -3.5,
				positionZ: 2
			},
			{
				positionX: -3.9,
				positionZ: -2
			},
			{
				positionX: -1,
				positionZ: -1.2
			},
			{
				positionX: 4,
				positionZ: -4
			},
			{
				positionX: 4,
				positionZ: 0
			}
		]
	},
	{ // layer 2
		material: 'epicMaterial',
		layerHeight: 2.26,
		contentId: 6,
		items:[
			{
				positionX: -3,
				positionZ: -2
			},
			{
				positionX: 1,
				positionZ: -2
			},
			{
				positionX: 1,
				positionZ: 3
			},
			{
				positionX: -2.5,
				positionZ: 2
			},
		]
	},
	{ // layer 3
		material: 'episodeMaterial',
		layerHeight: 4.26,
		contentId: 7,
		items:[
			{
				positionX: 2,
				positionZ: 2.5
			},
			{
				positionX: -2,
				positionZ: -1
			},
			{
				positionX: 2,
				positionZ: -1
			},
		]
	},
	{ // layer 4
		material: 'themeMaterial',
		layerHeight: 6.26,
		contentId: 8,
		items:[
			{
				positionX: -.8,
				positionZ: -.8
			},
		]
	},
	{ // layer 5
		material: 'sagaMaterial',
		layerHeight: 6.26,
		contentId: 9,
		items:[
			{
				positionX: .8,
				positionZ: .8
			},
		]
	},

];
