import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Theme from '../../style/theme';

export const ContentFrame = (props: ContentFrameProps) => {

	const title = useRef<HTMLHeadingElement>(null);
	const [titleWidth, setTitleWidth] = useState<number>(100);

	useEffect(() => {
		if(!title.current) return;
		setTitleWidth(title.current.clientWidth + 20);
	});

	return (
		<>
			{ props.children &&
				<Container>
					<Title ref={title}>{props.title}</Title>
					{props.children}
					<Outline titleWidth={titleWidth}/>
				</Container>	
			}
		</>
	);
};

// styled components

const Container = styled.div`

	position: relative;
	background-color: rgb(0,0,0,0.4);

	// Extra small screens
	@media (max-width: ${Theme.responsive.media.sm}){
		padding: ${Theme.responsive.whitespace.xs}px;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${Theme.responsive.media.sm}){
		padding: ${Theme.responsive.whitespace.sm / 2}px;
	}

	// Large screens
	@media (min-width: ${Theme.responsive.media.lg}){
		padding: ${Theme.responsive.whitespace.lg / 2}px;

	}
	// Extra large screens
	@media (min-width: ${Theme.responsive.media.xl}){
		padding: ${Theme.responsive.whitespace.xl / 3}px;
	}
`;

const Outline = styled.div<{titleWidth: number}>`
	position: absolute;
	inset:0;
	border: 1.5px solid ${Theme.colors.secondaryLightest};
	clip-path: ${p => `polygon(0 0, 10px 0, 10px 1.5px, ${p.titleWidth}px 1.5px, ${p.titleWidth}px 0, 100% 0, 100% 100%, 0 100%)`};
	/* background-color: green; */
`;

const Title = styled.h2`
	position: absolute;
	margin: 0;
	left:15px;top:-3px;
	line-height: 100%;
	transform: translateY(-50%);

	color: ${Theme.colors.secondaryLightest};
`;

// types

type ContentFrameProps = {
	children: ReactNode,
	title: string
}