import { Billboard, Html, Plane, Text } from '@react-three/drei';
import { GroupProps } from '@react-three/fiber';
import { useEffect, useState } from 'react';
import { useGlobalContext } from '../../context/contexts/global/GlobalContext';
import { PointerHandler } from '../ThreePointerHandler';
import { POI } from './POI';
import { WorkItemData, WorkItemGeometry, WorkItemMaterials } from './WorkItems';

export const WorkItem = (props: WorkItemProps & GroupProps) => {

	const {setActiveContentID, setGameState, gameState} = useGlobalContext();

	const [hover, setHover] = useState<boolean>(false);
	const [selected, setSelected] = useState<boolean>(false);

	useEffect(() => {
		if(selected && gameState !== 3){
			setSelected(false);
		}
	},[gameState]);

	useEffect(() => {
		if(selected)
			setHover(false);
	},[selected]);

	return(
		<group {...props}>
			<PointerHandler
				onClickCallback={() => {
					if(props.poi && setActiveContentID && setGameState){
						setActiveContentID(props.contentId);
						setGameState(3);
						setSelected(true);
					}
				}}
				onHoverCallback={(hover) => setHover(hover)}
				interactable={gameState === 2 && props.poi}
			>
				{ props.poi 
					? // poi object
					<>
						<mesh position={[0,0.6,0]} userData={{interactable: props.poi}}>
							<boxBufferGeometry args={[0.5,1.25,0.5]}/>
							<meshBasicMaterial transparent opacity={0} depthTest={false}/>
						</mesh>
						<POI passMaterials={props.passMaterials} groupData={props.groupData} focus={(hover || selected)}/>
					</>
					: // non poi object
					<>
						<Billboard position={[0,.5,0]} >
							<Plane args={[0.4,0.4]} material={props.passMaterials[props.groupData.material]} userData={{skipForHoverState: !props.poi, interactable: props.poi}}/>
						</Billboard>
						<mesh 
							geometry={props.passGeometry.tube} position={[0,0.15,0]}
							material={(hover || selected) ? props.passMaterials.hover : props.passMaterials.transparant}
							userData={{skipForHoverState: !props.poi, interactable: props.poi}}
						>
						</mesh>
					</>
				}
				{ props.poi && 
					<mesh 
						geometry={props.passGeometry.circle}
						material={(hover || selected) ? props.passMaterials.hover : props.passMaterials.transparant}
						rotation={[-Math.PI / 2,0,0]}
						userData={{skipForHoverState: !props.poi, interactable:props.poi}}
					/>
				}
			</PointerHandler>

		</group>
	);
};

// types

type WorkItemProps = {
	poi: boolean,
	contentId: number,
	passGeometry: WorkItemGeometry,
	passMaterials: WorkItemMaterials,
	groupData:  WorkItemData
}