import { createGlobalStyle } from 'styled-components';
import Theme from './theme';

// eslint-disable-next-line
const fonts = require('../assets/fonts/fonts.css');

const GlobalStyle = createGlobalStyle`

	html {
		height: -webkit-fill-available;
		min-height: 100%;
		height: 100%;	
		font-family: 'Univers', sans-serif;
		color: ${Theme.colors.neutralLightest};
		font-size: 20px;
	}

	body {
		margin:0;
		padding:0;
		height: 100%;
	}

	h1 {
		font-size: 3rem;
		margin-top: 0;
	}

	h2 {
		font-weight: 100;
		margin-top: 0;
	}

	p {
		font-weight: 50;
		margin: 0;
	}

	* {
		box-sizing: border-box;
	}

	#root {
		height: 100%;
	}

	/* custom scroll bar */

	/* width */
	::-webkit-scrollbar {
		width: 7.5px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: ${Theme.colors.neutral};
	}
	
	/* Handle */
	::-webkit-scrollbar-thumb {
		background:  ${Theme.colors.neutralLightest}; 
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #555; 
	}

`;

export default GlobalStyle;
