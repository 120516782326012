/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import React, { useEffect, useRef } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import model from './elevator.glb';
import { MeshStandardMaterial } from 'three';


type GLTFResult = GLTF & {
  nodes: {
    elevator_bottom_rings: THREE.Mesh;
    elevator_moving_cubes: THREE.Mesh;
    elevator_top_rings: THREE.Mesh;
    elevator_main: THREE.Mesh;
    elevator_lights: THREE.Mesh;
    elevator_outer_door_front_left: THREE.Mesh;
    Door_1004: THREE.Mesh;
    Door_1008: THREE.Mesh;
    elevator_outer_door_front_right: THREE.Mesh;
    Door_1010: THREE.Mesh;
    Object: THREE.Mesh;
  };
};

type ActionName = 'elevator';
type GLTFActions = Record<ActionName, THREE.AnimationAction>;

export const Elevator = (props: JSX.IntrinsicElements['group'] & {atlas: MeshStandardMaterial}) => {
	const group = useRef<THREE.Group>(null);
	const { nodes, animations } = useGLTF(
		model
	) as GLTFResult;
	const { actions } = useAnimations(animations, group);

	useEffect(() => {
		actions['elevator']?.play();
	},[actions]);

	useEffect(() => {
		if(!props.atlas) return;
		Object.keys(nodes).map(node => {
			nodes[node].material = props.atlas;
		});
	},[props.atlas]);

	return (
		<group ref={group} {...props} dispose={null} scale={1}>
			<group name="Scene">
				<group name="elevator_parent" scale={0.11664}>
					<group name="elevator_bottom_ring_parent" position={[0, -9.7, 0]}>
						<mesh
							name="elevator_bottom_rings"
							geometry={nodes.elevator_bottom_rings.geometry}
							material={nodes.elevator_bottom_rings.material}
						/>
						<mesh
							name="elevator_moving_cubes"
							geometry={nodes.elevator_moving_cubes.geometry}
							material={nodes.elevator_moving_cubes.material}
							position={[2.1, 0, -2.1]}
						/>
					</group>
					<group
						name="elevator_top_ring_parent"
						position={[0, 11.8, 0]}
						rotation={[Math.PI, 0, 0]}
					>
						<mesh
							name="elevator_top_rings"
							geometry={nodes.elevator_top_rings.geometry}
							material={nodes.elevator_top_rings.material}
						/>
					</group>
					<mesh
						name="elevator_main"
						geometry={nodes.elevator_main.geometry}
						material={nodes.elevator_main.material}
						position={[0, -9, 0]}
						scale={[1.694, 1.6, 1.56]}
					>
						<mesh
							name="elevator_lights"
							geometry={nodes.elevator_lights.geometry}
							material={nodes.elevator_lights.material}
							position={[0, 2.51441, -0.76923]}
							rotation={[0, 0, Math.PI / 2]}
							scale={[0.02993, 0.8138, 0.0307]}
						/>
						<mesh
							name="elevator_outer_door_front_left"
							geometry={nodes.elevator_outer_door_front_left.geometry}
							material={nodes.elevator_outer_door_front_left.material}
							position={[-0.9209, 1.25, -0.96154]}
							scale={[0.59032, 0.625, 0.64103]}
						>
							<mesh
								name="Door_1004"
								geometry={nodes.Door_1004.geometry}
								material={nodes.Door_1004.material}
								position={[0, 0, 0.5]}
							>
								<mesh
									name="Door_1008"
									geometry={nodes.Door_1008.geometry}
									material={nodes.Door_1008.material}
									position={[0, 0, 0.5]}
								/>
							</mesh>
						</mesh>
						<mesh
							name="elevator_outer_door_front_right"
							geometry={nodes.elevator_outer_door_front_right.geometry}
							material={nodes.elevator_outer_door_front_right.material}
							position={[-0.9209, 1.25, -0.96154]}
							scale={[0.59032, 0.625, 0.64103]}
						>
							<mesh
								name="Door_1010"
								geometry={nodes.Door_1010.geometry}
								material={nodes.Door_1010.material}
								position={[0, 0, 0.5]}
							>
								<mesh
									name="Object"
									geometry={nodes.Object.geometry}
									material={nodes.Object.material}
									position={[0, 0, 0.5]}
								/>
							</mesh>
						</mesh>
					</mesh>
				</group>
			</group>
		</group>
	);
};

useGLTF.preload(model);
