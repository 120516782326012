import styled, { keyframes } from 'styled-components';
import { useGlobalContext } from '../../context/contexts/global/GlobalContext';
import Theme from '../../style/theme';
import { ContentFrame } from './ContentFrame';
import CloseButton from '../../assets/images/closebutton.png';
import { useMemo } from 'react';
import { content } from './data';

export const Content = () => {

	const {gameState, setGameState, activeContentID} = useGlobalContext();

	const activeContent = useMemo(() => {
		if(activeContentID === undefined) return;
		return content[activeContentID];
	},[activeContentID]);

	return (
		<Container active={gameState === 3 ? true : false}>
			{ activeContent ? 
				<>
					<TitleBar>
						<h1>{activeContent.title}</h1>
						<Close onClick={() => setGameState && setGameState(2)}/>
					</TitleBar>
					<ContentFrame title={'Description'}>
						{activeContent.description}
					</ContentFrame>
					<ContentFrame title={'Events'}>
						{activeContent.events}
					</ContentFrame>
					<ContentFrame title={'Scope'}>
						{activeContent.scope}
					</ContentFrame>
					<ContentFrame title={'Relations'}>
						{activeContent.relations && activeContent.relations.parent}
					</ContentFrame>
				</>
				: 
				<TitleBar>
					<h1>no data found</h1>
					<Close onClick={() => setGameState && setGameState(2)}/>
				</TitleBar>
			}

		</Container>
	);
};

// keyframes

const slideIn = keyframes`
	0%{
		left:-50%;
	}
	100%{
		left:0;
	}
`;

const slideOut = keyframes`
	0%{
		left:0;
	}
	100%{
		left:-50%;
	}
`;

// styled components

const Container = styled.div<{active: boolean}>`

	position: absolute;
	left:0;top:0;bottom:0;
	width:50%;

	direction: rtl;

	& > * {
		direction: ltr;
	}

	display: flex;
	flex-direction: column;

	overflow-y: auto;

	/* background-color: hotpink; */

	animation: ${p => p.active ? slideIn : slideOut} 0.8s ease-out forwards;

	// Extra small screens
	@media (max-width: ${Theme.responsive.media.sm}){
		padding: ${Theme.responsive.whitespace.xs}px;
		gap: ${Theme.responsive.whitespace.xs * 1.5}px;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${Theme.responsive.media.sm}){
		padding: ${Theme.responsive.whitespace.sm}px;
		gap: ${Theme.responsive.whitespace.sm * 1.5}px;

	}

	// Large screens
	@media (min-width: ${Theme.responsive.media.lg}){
		padding: ${Theme.responsive.whitespace.lg}px;
		gap: ${Theme.responsive.whitespace.lg * 1.5}px;

	}
	// Extra large screens
	@media (min-width: ${Theme.responsive.media.xl}){
		padding: ${Theme.responsive.whitespace.xl}px;
		gap: ${Theme.responsive.whitespace.xl}px;
	}

	& h1 {
		margin: 0;
	}
`;

const TitleBar = styled.div`
	display:flex;

	& > h1 {
		color: ${Theme.colors.secondaryLightest};
	}
`;

const Close = styled.button`
	cursor: pointer;
	margin-left:auto;
	border:none;

	height: 50px;

	aspect-ratio: 1/1;
	background: rgba(0,0,0,0);

	background-image: url(${CloseButton});
	background-size: cover;
	background-repeat: no-repeat;

	transition: 0.3s;

	&:hover{
		transform: translateY(-5px);
	}

	&:active{
		transform: translateY(5px);
	}
`;