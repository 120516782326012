import styled, { keyframes } from 'styled-components';
import { useGlobalContext } from '../../context/contexts/global/GlobalContext';
import Theme from '../../style/theme';
import { Button } from '../buttons/Button';
import Loader from './Loader';

export const Introduction = () => {

	const {gameState, setGameState} = useGlobalContext(); 

	return (
		<> 
			<Container active={gameState === 1 ? true : false}>
				<Loader/>
				<h1>Title</h1>
				<h2>Subtitle</h2>
				<p>
					Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam quaerat repellat labore reiciendis corporis. Hic numquam saepe fugit aut soluta voluptate temporibus iure doloribus minima est aperiam, suscipit earum ullam facere quos architecto accusantium error perferendis sit sapiente nisi. Adipisci quae id inventore praesentium! Eveniet consequatur hic quibusdam quis fugiat!
				</p>
				{/* Content navigation */}
				<div style={{marginTop: 'auto'}}><Button onClick={() => setGameState && setGameState(2)}>start</Button></div>
			</Container>
		</>
	);
};

// keyframes

const load = keyframes`

	0% {
		left:100vw;
	}

	100% {
		left:0;
	}

`;

const slideOut = keyframes`

	0% {
		left:0;
	}
	100% {
		left: -50vw;
	}
`;

// styled components

const Container = styled.div<{active: boolean}>`

	position: absolute;
	left:100vw;
	top:0;
	bottom:0;
	width: 40vw;

	display: flex;
	flex-direction: column;

	background-color: ${Theme.colors.secondaryDarker};

	// Extra small screens
	@media (max-width: ${Theme.responsive.media.sm}){
		padding: ${Theme.responsive.whitespace.xs}px;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${Theme.responsive.media.sm}){
		padding: ${Theme.responsive.whitespace.sm}px;
	}

	// Large screens
	@media (min-width: ${Theme.responsive.media.lg}){
		padding: ${Theme.responsive.whitespace.lg}px;

	}
	// Extra large screens
	@media (min-width: ${Theme.responsive.media.xl}){
		padding: ${Theme.responsive.whitespace.xl}px;
	}

	padding-right: 0!important;

	// creates the triangle next to the container
	&:after{
		content: '';
		position: absolute;
		right:0;
		left: 100%;
		top:0;

		border-style: solid;
		border-width: 100vh 10vw 0 0;
		border-color: ${Theme.colors.secondaryDarker} transparent transparent transparent;
	}

	&:before{
		content: '';
		position: absolute;
		left:-100vw; top:0; bottom: 0;
		width: 100vw;

		background-color: ${Theme.colors.secondaryDarker};
	}

	// animations

	animation: ${p => p.active ? load : slideOut} 1s ease-out forwards;
	animation-delay: ${p => p.active ? '2s' : 0};

	z-index: 2;

`;