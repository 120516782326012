import ContextProvider from './context/ContextProvider';
import Interface from './interface/Interface';
import CustomThemeProvider from './style/CustomThemeProvider';
import { Three } from './three/Three';

function App() {
	return (
		<ContextProvider>
			<CustomThemeProvider>
				<Three/>
				<Interface/>
			</CustomThemeProvider>
		</ContextProvider>
	);
}

export default App;
