import styled from 'styled-components';
import { useGlobalContext } from '../context/contexts/global/GlobalContext';
import { Content } from './Content/Content';
import { DeveloperConsole } from './DeveloperConsole/DeveloperConsole';
import { Introduction } from './Introduction/Introduction';
import { ScreenSizeLock } from './ScreenSizeLock';
import { Tutorial } from './Tutorial/Tutorial';

export const Interface = () => {

	const {gameState} = useGlobalContext();

	return (
		<Container>
			<DeveloperConsole/>
			<ScreenSizeLock/>
			<Introduction/>
			<Tutorial/>
			<Content/>
		</Container>
	);
};

// styled components

const Container = styled.div`
    position: absolute;
    inset:0;
	pointer-events: none;

	& > * {
		pointer-events: all;
	}

	overflow: hidden;
`;

export default Interface;