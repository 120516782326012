import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Theme from '../style/theme';

export const ScreenSizeLock = () => {

	const [active, setActive] = useState<boolean>(false);
	const [windowSize, setWindowSize] = useState<{width: number, height: number}>({ width: window.innerWidth, height: window.innerHeight});

	useEffect(() => {
		window.addEventListener('resize', resizeHandler);

		return () => {
			window.removeEventListener('resize', resizeHandler);
		};
	},[]);

	useEffect(() => {
		if(windowSize.width > 600){
			setActive(false);
		}
		else{
			setActive(true);
		}
	},[windowSize]);

	const resizeHandler = () => {
		setWindowSize({width: window.innerWidth,  height: window.innerHeight});
	};

	return (
		<>
			{ active &&
				<Container>
					This experience requires a larger screen width. Please resize the window or use landscape mode by rotating your handheld device.
				</Container>
			}
		</>
	);
};

// styled components

const Container = styled.div`
	position: absolute;
	inset:0;

	background-color: ${Theme.colors.secondaryDarker};
	color: ${Theme.colors.neutralLightest};
	z-index: 9001;

	display: flex;
	justify-content: center;
	align-items: center;

	padding: 100px;
	text-align: center;
`;