import { Effects, Environment, Stats } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { ReactNode, useEffect } from 'react';
import styled from 'styled-components';
import { CubemapNX, CubemapNY, CubemapNZ, CubemapPX, CubemapPY, CubemapPZ } from '../assets/images/cubemap';
import { CursorState } from './ThreeCursorState';
import { Three } from './Three';
import { EffectComposer, Bloom } from '@react-three/postprocessing';
import { KernelSize } from 'postprocessing';

export const ThreeCanvas = (props: {children: ReactNode}) => {

	return (
		<CanvasContainer>
			<Canvas camera={{position: [4, 4, 4], fov: 80}} frameloop={'always'}>
				{/* <Stats/> */}
				<directionalLight position={[5,10,20]}intensity={0.5} color={'white'}/>
				<ambientLight intensity={0.5} color={'white'}/>
				<EffectComposer multisampling={8}>
					<Bloom kernelSize={1} luminanceThreshold={0} luminanceSmoothing={0} intensity={0.2} />
				</EffectComposer>
				<Environment
					files={[CubemapPX, CubemapNX, CubemapPY, CubemapNY, CubemapPZ, CubemapNZ]}
					background={true}
				/>
				{props.children}
			</Canvas>
		</CanvasContainer>
	);
	
};

// styled components

const CanvasContainer = styled.div`

	height: 100%;
    width: 100%;

`; 