/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import React, { useEffect, useRef, useState } from 'react';
import { useGLTF, useAnimations, Line, Html, Text } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import { MeshBasicMaterial, MeshPhysicalMaterial } from 'three';
import Theme from '../../style/theme';
import { useGlobalContext } from '../../context/contexts/global/GlobalContext';
import { content } from '../../interface/Content/data';
import styled from 'styled-components';
import { WorkItems } from './WorkItems';
import font from '../../assets/fonts/Univers LT 67 Condensed Bold.ttf';
import { PointerHandler } from '../ThreePointerHandler';

const lineOffset = 0.2;

export const Layer = (props: JSX.IntrinsicElements['group'] & LayerProps) => {

	const {setActiveContentID, setGameState, gameState} = useGlobalContext();

	const group = useRef<THREE.Group>(null);
	const layer = useRef<THREE.Mesh>(null);

	const [hover, setHover] = useState<boolean>(false);
	const [selected, setSelected] = useState<boolean>(false);

	useEffect(() => {
		if(selected && gameState !== 3){
			setSelected(false);
		}
	},[gameState]);

	useEffect(() => {
		if(selected)
			setHover(false);
	},[selected]);

	const onClickHandler = () => {
		if(gameState !== 2)return;
		setActiveContentID && setActiveContentID(props.index); 
		setGameState && setGameState(3); 
		setSelected(true);
	};

	return (
		<group ref={group} {...props} dispose={null} renderOrder={2}>
			<PointerHandler onClickCallback={() => onClickHandler()} interactable={gameState === 2} onHoverCallback={(hover) => setHover(hover)}>
				<mesh 
					ref={layer}
					material={props.material} 
					userData={{interactable: true}}
				>
					<boxGeometry args={[props.width,props.height,props.width]}/>
				</mesh>
				{ (hover || selected) &&
				<Line
					points={[
						[props.width / 2 + lineOffset , 0, -props.width / 2 - lineOffset], 
						[props.width / 2 + lineOffset, 0, props.width / 2 + lineOffset],
						[-props.width / 2 - lineOffset, 0, props.width / 2 + lineOffset],
						[-props.width / 2 - lineOffset, 0, -props.width / 2 - lineOffset],
						[props.width / 2 + lineOffset, 0, -props.width / 2 - lineOffset],
					]}
					lineWidth={2}                   // In pixels (default)
					dashed={false}                  // Default
					color={Theme.colors.primary}
				/>
				}
				<Text 
					font={font} 
					fontSize={0.4}
					position={[0,0,props.width / 2 + 0.01]}
				>
					{content[props.index].title}
				</Text>
				<Text 
					font={font} 
					fontSize={0.4}
					position={[0,0,-props.width / 2 - 0.01]}
					rotation={[0,-Math.PI, 0]}
				>
					{content[props.index].title}
				</Text>
				<Text 
					font={font} 
					fontSize={0.4}
					position={[props.width / 2 + 0.01,0,0]}
					rotation={[0,Math.PI / 2, 0]}

				>
					{content[props.index].title}
				</Text>
				<Text 
					font={font} 
					fontSize={0.4}
					position={[-props.width / 2 - 0.01,0,0]}
					rotation={[0,-Math.PI / 2, 0]}
				>
					{content[props.index].title}
				</Text>
			</PointerHandler>
		</group>
	);
};

// styled components

// types

type LayerProps = {
	material: MeshBasicMaterial | MeshPhysicalMaterial;
	width: number,
	height: number,
	index: number,
}
