/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import React, { useEffect, useRef } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import model from './escalator.glb';
import { MeshStandardMaterial } from 'three';


type GLTFResult = GLTF & {
  nodes: {
    escalator_end_steps: THREE.Mesh;
    escalator_handrail: THREE.Mesh;
    escalator_middle_steps: THREE.Mesh;
    escalator_encasing: THREE.Mesh;
  };
};

type ActionName = 'escalator';
type GLTFActions = Record<ActionName, THREE.AnimationAction>;

export const Escalator = (props: JSX.IntrinsicElements['group'] & {atlas: MeshStandardMaterial}) => {
	const group = useRef<THREE.Group>(null);
	const { nodes, animations } = useGLTF(
		model
	) as GLTFResult;
	const { actions } = useAnimations(animations, group);

	useEffect(() => {
		actions['escalator']?.play();
		console.log('tes1223');
	},[actions]);
	
	useEffect(() => {
		if(!props.atlas) return;
		console.log(props.atlas);
		Object.keys(nodes).map(node => {
			nodes[node].material = props.atlas;
		});
	},[props.atlas]);

	return (
		<group ref={group} {...props} dispose={null} onPointerEnter={(e) => e.stopPropagation()}>
			<group name="Scene">
				<group name="escalator_parent" scale={0.078}>
					<mesh
						name="escalator_end_steps"
						geometry={nodes.escalator_end_steps.geometry}
						material={nodes.escalator_end_steps.material}
					/>
					<mesh
						name="escalator_handrail"
						geometry={nodes.escalator_handrail.geometry}
						material={nodes.escalator_handrail.material}
						position={[-1, 0.4037, 0]}
					/>
					<mesh
						name="escalator_middle_steps"
						geometry={nodes.escalator_middle_steps.geometry}
						material={nodes.escalator_middle_steps.material}
						position={[1, -1, 0]}
					/>
					<mesh
						name="escalator_encasing"
						geometry={nodes.escalator_encasing.geometry}
						material={nodes.escalator_encasing.material}
						position={[17.3, -11.5, 0]}
						rotation={[-Math.PI, 0, -Math.PI]}
						scale={[1, 0.56, 1]}
					/>
				</group>
			</group>
		</group>
	);
};

useGLTF.preload(model);