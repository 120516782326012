import * as THREE from 'three';
import React, { useEffect, useRef } from 'react';
import { useGLTF, Float } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import model from '../../assets/models/pyramid.gltf';
import { useGlobalContext } from '../../context/contexts/global/GlobalContext';
import abnLogo from '../../assets/images/abn_logo.png';
import { useLoader } from '@react-three/fiber';
import { ThreeCameraActions } from '../ThreeCameraActions';
import { Layer } from './Layer';
import { Escalator } from './Escalator';
import { WorkItems } from './WorkItems';
import { Elevator } from './Elevator';
import { MeshBasicMaterial, MeshStandardMaterial, TextureLoader } from 'three';





const material = new THREE.MeshPhysicalMaterial({
	color: new THREE.Color('#005349'),
	roughness: 0.7,
	metalness: 0,
	envMapIntensity: 0,
});

const layerHeight = 0.5;
const layerGap = 2;
const layerSizeDecrease = 2;
const layerWidth = 10;

export const Pyramid = (props: JSX.IntrinsicElements['group']) => {
	const group = useRef<THREE.Group>(null);

	const {atlas} = useGlobalContext();

	return (
		<>
			{/* Pyramid */}
			<group ref={group} {...props} dispose={null}>
				<ThreeCameraActions object={group.current}/>
				{atlas && 
					<>
						<Escalator position={[-2.22,6.2,-1.36]} rotation={[0, -Math.PI / 2, 0]} atlas={atlas}/> 
						<Escalator position={[-2,2.2,-4.27]} rotation={[0, Math.PI, 0]} atlas={atlas}/>
						<Elevator position={[2,3.4,3.2]} rotation={[0,Math.PI / 2,0]} atlas={atlas}/>
						<Elevator position={[1.2,7.4,0]} rotation={[0,0,0]} atlas={atlas}/>
					</>
				}
				{[...Array(5)].map((value, index) => 
					<Layer 
						key={`pyramid-layer-${index}`}
						position={[0,index * layerGap,0]}
						material={material} 
						height={layerHeight}
						width={layerWidth - (layerSizeDecrease * index)}
						index={index}
					/>
				)}
				<WorkItems/>
			</group>
		</>

	);
};

// types
