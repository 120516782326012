import styled from 'styled-components';
import Theme from '../../style/theme';

// export const Button = () => {
// 	return (

// 	);
// };

// styled components

export const Button = styled.button`

	border: none;

	font-weight: 300;
	font-family: 'Univers';
	font-size: 1.5rem;

	background-color: ${Theme.colors.primary};
	color: ${Theme.colors.secondaryDarker};
	padding: .5rem 2rem;

	cursor: pointer;

	&:hover{
		filter: grayscale(30%);
	}
`;