import { Pyramid } from '../models/Pyramid';
import { SpaceDust } from '../particles/Spacedust';

export const Scene = () => {

	return (
		<>	
			<SpaceDust count={3000}/>
			<Pyramid position={[0,0,0]}/>
		</>
	);

}; 