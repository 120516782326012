import { ThreeCanvas, ThreeControls } from '.';
import { Scene } from './scenes';
import { useContextBridge } from '@react-three/drei';
import { GlobalContext } from '../context/contexts/global/GlobalContext';
import { CursorState } from './ThreeCursorState';

export const Three = () => {

	const ContextBridge = useContextBridge(GlobalContext);
    
	return (
		<ThreeCanvas>
			<ContextBridge>
				<CursorState/>
				<ThreeControls/>
				<Scene/>
			</ContextBridge>
		</ThreeCanvas>
	);

};