import closebutton from '../../assets/images/closebutton.png';
import { Pyramid } from '../../three/models/Pyramid';

export const content : ContentItem[] = [

	// pyramid layers

	{
		title: 'Block',
		description: 'test layer description',
		events: 'test event',
	},

	{
		title: 'Super block',
		description: 'test layer description',
		events: 'test event',
	},

	{
		title: 'Grid',
		description: 'test layer description',
		events: 'test event',
	},

	{
		title: 'Portfolio',
		description: 'test layer description',
		events: 'test event',
	},

	{
		title: 'Enterprise',
		description: 'test layer description',
		events: 'test event',
	},

	// work items

	{
		title: 'stories',
		icon: closebutton,
		description: 'test description',
		scope: 'test scope',
		relations: {
			parent: 2,
			children: [
				1,
				2
			]
		}
	},
	{
		title: 'epics',
		icon: closebutton,
		description: 'test description',
		scope: 'test scope',
		relations: {
			parent: 2,
			children: [
				1,
			]
		}
	},
	{
		title: 'episodes',
		icon: closebutton,
		description: 'test description',
		scope: 'test scope',
		relations: {
			parent: 2,
			children: [
				1,
			]
		}
	},
	{
		title: 'saga',
		icon: closebutton,
		description: 'test description',
		scope: 'test scope',
		relations: {
			parent: 2,
			children: [
				1,
			]
		}
	},
	{
		title: 'theme',
		icon: closebutton,
		description: 'test description',
		scope: 'test scope',
		relations: {
			parent: 2,
			children: [
				1,
			]
		}
	},
	

];

// types

type ContentItem = {

	// generic 
	title: string,
	description: string,

	// work item
	icon?: string,
	scope?: string,
	relations?: {
		parent: number,
		children: number[]
	},

	// layer item
	events?: string,
}