import { useFBO } from '@react-three/drei';
import { ThreeEvent } from '@react-three/fiber';
import { release } from 'os';
import { ReactNode, useState } from 'react';

const movesThreshold = 5;

export const PointerHandler = (props: PointerHandlerProps) => {

	const [down, setDown] = useState<boolean>();
	const [moves, setMoves] = useState<number>(0);

	const release = () => {
		if(moves >= movesThreshold || !down) {
			//
		}
		else{
			props.onClickCallback();
		}

		setDown(false);
		setMoves(0);

	};

	const move = (e: ThreeEvent<PointerEvent>) => {

		if(!down) return;
		setMoves(m => m + 1);
	};

	return (
		<group
			onPointerDown={(e) => {
				if(!props.interactable) return;
				e.stopPropagation();
				setDown(true);
			}}
			onPointerMove={(e) => {
				if(!props.interactable) return;
				e.stopPropagation();
				move(e);
			}}
			onPointerUp={(e) => {
				if(!props.interactable) return;
				e.stopPropagation();
				release();
			}}
			onPointerLeave={(e) => {
				if(!props.interactable) return;
				e.stopPropagation();
				setDown(false);
				setMoves(0);
				props.onHoverCallback && props.onHoverCallback(false);
			}}
			onPointerEnter={(e) => {
				if(!props.interactable) return;
				e.stopPropagation();
				props.onHoverCallback && props.onHoverCallback(true);

			}}
		>
			{props.children}
		</group>
	);
};

type PointerHandlerProps = {
	children: ReactNode;
	interactable? : boolean;
	onClickCallback: () => void;
	onHoverCallback?: (boolean) => void;
}