import { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import indicator from '../../assets/images/tutorial_indicator.png';
import { useGlobalContext } from '../../context/contexts/global/GlobalContext';

export const Tutorial = () => {

	const {controls, gameState} = useGlobalContext();
	const [controlsStarted, setControlsStarted] = useState<boolean>(false);
	const [state, setState] = useState<number>(0);

	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if(gameState === 2){
			controls?.addEventListener('controlstart', handleControlStart);
			setState(a => a + 1);
		}

		return () => {
			controls?.removeEventListener('controlstart', handleControlStart);
		};

	},[gameState]);

	const handleControlStart = () => {
		setControlsStarted(true);
		ref.current?.addEventListener('animationend', () => setState(a => a + 1));
		controls?.removeEventListener('controlstart', handleControlStart);
	};

	return(
		<>
			<Container fadeOut={controlsStarted} active={state === 1 ? true : false} ref={ref}>
				<ImageContainer><Image/></ImageContainer>
				<h2>click and drag to look around</h2>
			</Container>
		</>
	);
};

// keyframes

const image = keyframes`
	0%{
		right:0;
	}
	50%{
		right: calc(100% - 150px);
	}
	100%{
		right:0;
	}
`;

const fadeOut = keyframes`
	0%{
		opacity: 1;
	}
	100%{
		opacity: 0;
	}
`;

const fadeIn = keyframes`
	0%{
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
`;

// styled components

const Container = styled.div<{fadeOut: boolean, active: boolean}>`
	position: absolute;
	left:50%;
	top:50%;

	transform: translate(-50%, -50%);

	display: ${p => p.active ? 'flex' : 'none' };
	flex-direction: column;

	pointer-events: none!important;

	animation: ${p => !p.active ? '' : p.fadeOut ? fadeOut : fadeIn} 1s forwards;	

`;

const ImageContainer = styled.div`
	position: relative;
	height: 150px;
	/* background-color: green; */
`;

const Image = styled.div`
	position: absolute;
	right:100%;
	height: 100%;
	aspect-ratio: 1/1;

	background-image: url(${indicator});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	
	animation: ${image} 3s infinite;
`;