/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three';
import React, { useEffect, useRef } from 'react';
import { useGLTF, useAnimations, Plane, Billboard } from '@react-three/drei';
import { GLTF } from 'three-stdlib';
import model from './poi.glb';
import { useGlobalContext } from '../../context/contexts/global/GlobalContext';
import { WorkItemData, WorkItemMaterials } from './WorkItems';
import { seededRandom } from 'three/src/math/MathUtils';

type GLTFResult = GLTF & {
  nodes: {
    poi_top_ring_middle_big: THREE.Mesh;
    poi_top_ring_middle_small: THREE.Mesh;
    poi_top_ring_outer_big: THREE.Mesh;
    poi_top_ring_outer_small: THREE.Mesh;
    poi_top_ring_outer_small_02: THREE.Mesh;
    poi_pillar: THREE.Mesh;
    ring_bottom: THREE.Mesh;
    ring_mid: THREE.Mesh;
    ring_top: THREE.Mesh;
  };
};


type ActionName = 'poi_idle';
type GLTFActions = Record<ActionName, THREE.AnimationAction>;

export const POI = (props: JSX.IntrinsicElements['group'] & {passMaterials: WorkItemMaterials, groupData: WorkItemData, focus: boolean}) => {
	const group = useRef<THREE.Group>(null);
	const { nodes, animations } = useGLTF(
		model
	) as GLTFResult;

	const small = useRef<THREE.Mesh>(null);

	const {atlas} = useGlobalContext();
	const { actions } = useAnimations(animations, group);
	useEffect(() => {
		actions['poi_idle']?.play();
	},[actions]);

	useEffect(() => {

		if(!atlas) return;

		Object.keys(nodes).map(node => {
			nodes[node].material = atlas;
		});

	},[atlas]);

	return (
		<group ref={group} {...props} dispose={null}>
			<group name="Scene">
				<Billboard position={[0,0.94,0]}>
					<Plane args={[.26,.26]} material={props.passMaterials[props.groupData.material]}/>
				</Billboard>
				<group name="point_of_interest_parent" scale={2.34}>
					<mesh
						name="poi_top_ring_middle_big"
						castShadow
						receiveShadow
						geometry={nodes.poi_top_ring_middle_big.geometry}
						material={nodes.poi_top_ring_middle_big.material}
						position={[0, 0.4, 0]}
						rotation={[0, 0, 2.09]}
						scale={[0.7, 0.7, 1]}
					/>
					<mesh
						name="poi_top_ring_middle_small"
						castShadow
						receiveShadow
						geometry={nodes.poi_top_ring_middle_small.geometry}
						material={props.focus ? props.passMaterials.hover : nodes.poi_top_ring_middle_small.material}
						position={[0, 0.4, 0]}
						rotation={[0, 0, 2.09]}
						scale={[0.7, 0.7, 1]}
					/>
					<mesh
						name="poi_top_ring_outer_big"
						castShadow
						receiveShadow
						geometry={nodes.poi_top_ring_outer_big.geometry}
						material={nodes.poi_top_ring_outer_big.material}
						position={[0, 0.4, 0]}
						rotation={[0, 0.08, 0]}
					>
					</mesh>
					<mesh
						name="poi_top_ring_outer_small"
						castShadow
						receiveShadow
						geometry={nodes.poi_top_ring_outer_small.geometry}
						material={props.focus ? props.passMaterials.hover : nodes.poi_top_ring_outer_small.material}
						position={[0, 0.4, 0]}
					/>
					<mesh
						name="poi_top_ring_outer_small_02"
						castShadow
						receiveShadow
						geometry={nodes.poi_top_ring_outer_small_02.geometry}
						material={props.focus ? props.passMaterials.hover : nodes.poi_top_ring_outer_small_02.material}
						position={[0, 0.4, 0]}
						rotation={[0, -0.01, 2.53]}
					/>
					<mesh
						name="poi_pillar"
						castShadow
						receiveShadow
						geometry={nodes.poi_pillar.geometry}
						material={props.focus ? props.passMaterials.hover : nodes.poi_pillar.material}
						position={[0, 0.16, 0]}
					>
						<mesh
							name="ring_bottom"
							castShadow
							receiveShadow
							geometry={nodes.ring_bottom.geometry}
							material={props.focus ? props.passMaterials.hover : nodes.ring_bottom.material}
							position={[0, 0.03, 0]}
						/>
						<mesh
							name="ring_mid"
							castShadow
							receiveShadow
							geometry={nodes.ring_mid.geometry}
							material={props.focus ? props.passMaterials.hover : nodes.ring_mid.material}
							position={[0, 0.06, 0]}
							scale={2}
						/>
						<mesh
							name="ring_top"
							castShadow
							receiveShadow
							geometry={nodes.ring_top.geometry}
							material={props.focus ? props.passMaterials.hover : nodes.ring_top.material}
							position={[0, 0.09, 0]}
							scale={3}
						/>
					</mesh>
				</group>
			</group>
		</group>
	);
};

useGLTF.preload(model);
