import CameraControls from 'camera-controls';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useLoader } from '@react-three/fiber';
import { TextureLoader, MeshStandardMaterial } from 'three';

import atlasEm from '../../../assets/images/atlas_em.png';
import atlasColor from '../../../assets/images/atlas_color.png';

const GlobalContext = createContext<Partial<GlobalContextProps>>({});

const GlobalContextProvider = (props: {children: ReactNode}) => {

	// general

	// gameState; this value dictates the current state of the game.
	const [gameState, setGameState] = useState<number>(process.env.NODE_ENV === 'production' ? 1 : 1);
	const [overwriteGameState, setOverwriteGameState] = useState<number | undefined>();
	const [activeContentID, setActiveContentID] = useState<number | undefined>(undefined);
	
	useEffect(() => {

		const ov = localStorage.getItem('overwriteGameState');
		if(ov === 'undefined' || ov === null || ov === 'null') return;
		setOverwriteGameState(parseInt(ov));
		setGameState(parseInt(ov));

	},[]);

	// three

	const [controls, setControls] = useState<CameraControls | null>(null);
	const [atlas, setAtlas] = useState<MeshStandardMaterial>();

	const [color, emissive] = useLoader(TextureLoader, [
		atlasColor,
		atlasEm
	]);

	useEffect(() => {
		if(!color && !emissive) return;
		console.log(emissive);
		setAtlas(new MeshStandardMaterial({map: color, emissiveMap: emissive, emissiveIntensity: 0.5, emissive:'white', roughness: 0.5, metalness: 1}));
	},[color, emissive]);

	// passing values & functions

	const passedFunctions = {

		setGameState,
		setOverwriteGameState,

		setControls,

		setActiveContentID

	};

	const passedValues = {

		gameState,
		overwriteGameState,

		controls,

		activeContentID,

		atlas

	};		

	//#region render

	return (
		<GlobalContext.Provider value={{...passedValues, ...passedFunctions}}>
			{props.children}
		</GlobalContext.Provider>
	);

	//#endregion
};

// types

type GlobalContextProps = {

	// general
	gameState: number,
	setGameState: (state: number) => void,
	overwriteGameState: number | undefined,
	setOverwriteGameState: (val: number | undefined) => void

	// three
	controls: CameraControls | null
	setControls: (controls: CameraControls | null) => void

	atlas: MeshStandardMaterial


	// content
	activeContentID: number | undefined,
	setActiveContentID: (id: number | undefined) => void,


}


// exports

const useGlobalContext = () => useContext(GlobalContext);

export {GlobalContextProvider, GlobalContext, useGlobalContext};
