import { useEffect, useState } from 'react';
import { useGlobalContext } from '../../../context/contexts/global/GlobalContext';

const amountOfStates = 4;

export const DCGeneral = () => {

	const {gameState, setGameState, overwriteGameState, setOverwriteGameState} = useGlobalContext();
	const [overwriteValue, setOverwriteValue] = useState<number | undefined>(undefined);

	useEffect(() => {
		localStorage.setItem('overwriteGameState', JSON.stringify(overwriteGameState));
	},[overwriteGameState]);

	return(
		<>
			<label>gameState</label>
			<select onChange={(e) => setGameState && setGameState(parseInt(e.currentTarget.value))} value={gameState}>
				{ [...Array(amountOfStates)].map((option, index) => <option key={`dc-general-gamestate-option-${index}`}>{index}</option>)}
			</select>
			<label>overwrite initial gameState</label>
			<select onChange={(e) => setOverwriteGameState && setOverwriteGameState(parseInt(e.currentTarget.value))} value={overwriteGameState}>
				<option>no overwrite</option>
				{ [...Array(amountOfStates)].map((option, index) => <option key={`dc-general-gamestate-overwrite-option-${index}`}>{index}</option>)}
			</select>
		</>
	);
};